<template>
    <div class="Map">
        <baidu-map class="map" :center="{lng:116.404,lat:39.915} " :zoom="17" style="height:300px" ak="lwRqa6zm0qTIek4xqKp33iwVFt8feGEA">
            <bm-marker :position="{lng:116.404,lat:39.915}"></bm-marker>
        </baidu-map>

    </div>
</template>

<script>
    import baiduMap from "vue-baidu-map/components/map/Map.vue"
    import bmMarker from "vue-baidu-map/components/overlays/Marker.vue";
    export default {
        name: "",
        components: {
            baiduMap,
            bmMarker
        },
        methods: {
         
        }
    }
</script>

<style scoped>
    
</style>