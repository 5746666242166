<template>
  <div>$END$</div>
</template>

<script>
export default {
name: ""
}
</script>

<style scoped>

</style>