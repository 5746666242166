<template>
    <div class="header">
        <div class="left">
            <img class="logo" src="/image/job/logo.png" alt="">
            <div class="control">
                <img src="/image/job/left.png" alt="">
                <img src="/image/job/right.png" alt="">
            </div>
            <div class="control">
                <img src="/image/job/delete.png" alt="">
                <img src="/image/job/image1.png" alt="">
                <img src="/image/job/recovery.png" alt="">
                <img src="/image/job/shezhi.png" alt="">
                <img src="/image/job/edit.png" alt="">
            </div>
        </div>
        <div class="right">
            <div class="control">
                <img src="/image/job/debug.png" alt="">
                <img src="/image/job/start.png" alt="">
                <img src="/image/job/user.png" alt="">
            </div>
            <div class="open" @click="changeOpen">
                <img src="/image/job/open.png" alt="">
                {{ open? '收起': '展开' }}
            </div>
        </div>
    </div>
</template>

<script>
    
    export default {
        name: "Job",
        components:{
           
       },
       data(){
        return{
            open: true
        }
       },
       methods: {
        changeOpen() {
            this.open = !this.open
            this.$emit("changeOpen")
        }
       }
    }
</script>

<style scoped>
    .header{
        width: 96%;
        height: 90px;
        display: flex;
        align-items: center;
        border: 1px solid #DADFE7;
        padding: 0 1.9%;
        justify-content: space-between;
    }

    .header .left{
        display: flex;
        align-items: center;
    }
    .header .left .logo{
        width: 190px;
        height: 50px;
        margin-right: 30px;
    }

    .header .control img{
        width: 28px;
        height: 28px;
        margin-right: 20px;
        cursor: pointer;
    }

    .header .control{
        padding: 0 30px;
        border-right: 1px solid #DADFE7;
    }

    .header .left .control:last-child{
        border-right: none;
    }

    .header .right{
        display: flex;
    }
    
    .header .right .open{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        color: #4D5E80;
        cursor: pointer;
    }

    .header .right .open img{
        margin: 0 10px 0 30px;
    }
</style>