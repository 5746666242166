<template>
  <div>
      <div id="box1">
        <center>
        <p>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        </center>
      </div>
    <div id="box2">
      <ul>
        <li>
          <img src="/image/img1.jpeg" style="width:100px;height:250px;">
        </li>
        <li>
          <img src="/image/img2.jpeg" style="width:100px;height:250px;">
        </li>
        <li>
          <img src="/image/img3.jpeg" style="width:100px;height:250px;">
        </li>
        <li>
          <img src="/image/img4.jpeg" style="width:100px;height:250px;">
        </li>
      </ul>
    </div>
    <div id="box3">
      <h1 class="title1">标题1</h1>
      <p class="p1">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
      <p class="p1">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
      <p class="p1">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
      <p class="p1">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
      <p class="p1">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
      <p class="p1">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>


    </div>
    <div id="box4">
      <h1 class="title2">标题2</h1>
      <p class="p2">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
      <p class="p2">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
      <p class="p2">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
      <p class="p2">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
      <p class="p2">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
      <p class="p2">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
    </div>
  </div>

</template>

<script>
export default {
  name: "core_tec_style"
}
</script>

<style scoped>
  *{
    margin:0px;
    padding: 0px;
    list-style: none;
    overflow:hidden;
  }
  #box1{
    position: relative;
    top:10px;
    height: 150px;

  }
  #box2{
    position: relative;
    top:0px;
    margin-left: 5%;
    width:80%;
    height:400px;
    background-color: rgb(244,249,253);
  }
  ul{
    position:relative;
  }
  ul li{
    position:relative;
    float:left;
  }
  ul li img{
    position: relative;
    margin-top: 75px;
    margin-left: 100px;
    margin-right: 0px;
  }
  #box3{
    position:relative;
    float:left;
    top:50px;
    width:40%;
    height: 400px;
    margin-left: 5%;
    margin-right: 5%;
  }
  #box4{
    position:relative;
    float:left;
    top:50px;
    width:40%;
    height: 400px;
    margin-left: 5%;
  }
  .title1{
    position: relative;
    top:20px;
    color: lightblue;
  }
  .p1{
    position: relative;
    top:50px;
  }
  .title2{
    position: relative;
    top:20px;
    color: lightblue;
  }
  .p2{
    position: relative;
    top:50px;
  }

</style>