<template>
    <div>
      <div id="box1">
        <center><h1 class="title1">标题1</h1></center>
        <p>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
      </div>
      <div id="box2">
        <h2 class="title2">标题2</h2>
        <p class="p1">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p class="p1">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p class="p1">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p class="p1">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p class="p1">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p class="p1">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p class="p1">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>

      </div>
      <div id="box3" >
          <img src="/image/img_12.png" style="width:100%;height: 400px">
      </div>

      <div id="box4" >
        <img src="/image/img_12.png" style="width:100%;height: 400px">
      </div>
      <div id="box5">
        <h2 class="title3">标题3</h2>
        <p class="p2">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p class="p2">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p class="p2">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p class="p2">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p class="p2">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p class="p2">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
        <p class="p2">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>

      </div>
    </div>
</template>

<script>
export default {
  name: "introduce1"
}
</script>

<style scoped>
*{
  margin:0px;
  padding:0px;
  list-style: none;
}
#box1{
  position:relative;
  margin-left: 10%;
  width:80%;
  height:300px;
  text-align: center;
}
#box2{
  position: relative;
  top:30px;
  float:left;
  width:35%;
  height:400px;
  margin-left: 10%;
}
#box3{
  position: relative;
  top:30px;
  float:left;
  width: 35%;
  height:400px;
  margin-left: 10%;
}
.title2{
  position:relative;
  top:30px;
  color:lightblue;
}
.p1{
  position: relative;
  top:50px;
}
#box4{
  position: relative;
  top:30px;
  float:left;
  width: 35%;
  height:400px;
  margin-left: 10%;
}
#box5{
  position: relative;
  top:30px;
  float:left;
  width:35%;
  height:400px;
  margin-left: 10%;
}
.title3{
  position:relative;
  top:30px;
  color:lightblue;
}
.p2{
  position: relative;
  top:50px;
}
</style>