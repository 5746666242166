<template>
    <div id="plpip">
        <HomeHeader></HomeHeader>
        <HomeBanner></HomeBanner>
        <HomeDevelop></HomeDevelop>
        <HomeResearch></HomeResearch>
        <HomeIntroduce></HomeIntroduce>
        <HomeFooter></HomeFooter>


    </div>
</template>
<script>
    import HomeHeader from '../Header'
    import HomeBanner from '../Home/Homecomponents/Banner'
    import HomeFooter from '../Footer'
    export default {
        name: "Home",
        components:{
            HomeHeader,
            HomeBanner,
            HomeFooter,
        },
        data(){
            return{

            }
        },

    }
</script>

<style>
    html,body,
    #plpip{
        width:100%;
        height: 100%;
        margin: 0;
        padding: 0;
        position: absolute;
    }

</style>