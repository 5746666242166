<template>
    <div class="header">
        <div class="header-content">
            <div class="center">
                <div class="top">
                    <img class="logo" src="/image/home/logo.png" alt="">
                    <ul class="header-ul">      
                        <!-- <li class="header-li">
                            <router-link to="/Job" class="router">
                                立即体验
                            </router-link>
                        </li>
                        <li class="header-li">
                            <router-link to="/Job" class="router">
                                联系我们
                            </router-link>
                        </li> -->
                        
                        <!-- <li class="header-li">
                            <img src="/image/home/lang.png" alt="">
                            <img src="/image/home/down.png" alt="">
                        </li> -->
                    </ul>
                </div>

                <div class="bottom">
                    <p>
                        <span class="text1">ECloudSpace</span>
                        <span class="text2">致力于打造硬件学习一体化平台</span>
                        <span class="text3">解决硬件学习中入门成本高、学习效率低、学习工具不便捷等问题，围绕“仿真平台+硬件+课程体系”三个板块，帮助硬件初学者更简单快乐的学习</span>
                    </p>
                    <ul class="header-ul">      
                        <li class="header-li-bottom">
                            <span to="" class="router" @click="open('https://ide.yunfengcloud.cn:2443/')">
                                立即体验 <img src="/image/home/right.png" alt="">
                            </span>
                        </li>
                        <li class="header-li-bottom">
                            <span class="router" @click="open('https://rw7mwduiy2.feishu.cn/wiki/SgEowNH4ViwRalkuDJRcQolwnpe')">
                                使用指南 <img src="/image/home/right.png" alt="" >
                            </span>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
        <div class="header-m"></div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                activeIndex: '1',
                activeIndex2: '1'
            };
        },
        methods: {
            handleSelect(key, keyPath) {
                console.log(key, keyPath);
            },
            open(link) {
                window.open(link)
            }
        }
    }
</script>

<style scoped>
    .header{
        /* width: 1500px; */
        height: 470px;
        background: #002A8E;
    }

    .header-content{
        /* height: 100%; */
        background-image: url("/image/home/header-bg.png");
        background-size: cover;
        display: flex;
        justify-content: center;
        position: relative;
    }

    .header-m{
        background: linear-gradient(180deg, rgba(0,42,142,0.5) 0%, rgba(0,42,142,0.2) 100%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 71px;
        z-index: 1;
    }

    .header-content .center{
        width: 63%;
        margin-top: 13px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .header-content .center .top{
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 2;
    }

    .top .logo{
        image-rendering: crisp-edges;
    }

    .header-ul{
        list-style-type: none;
        display: flex;
        padding: 0;
    }
    .header-li{
        float: right;
        position: relative;
        line-height: 30px;
        font-size: 16px;
        width: 98px;
        height: 30px;
        text-align: center;
        border: 1px solid #fff;
        margin-right: 10px;
    }

    .header-li:hover{
        scale: 1.1;
    }

    .header-li:first-child{
        background-color: #006FFF ;
        border: 1px solid#006FFF ;

    }

    /* .header-li:last-child{
        width: 60px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
    } */

    .header-li .router,.header-li-bottom .router{
        text-decoration: none;
        display:block;
        outline:0;
        color: #fff;
        cursor: pointer;
    }

    .bottom{
        width: 100%;
        color: #FFFFFF;
        margin-top: 50px;
        margin-bottom: 40px;
    }

    .bottom .text1{
        font-size: 43px;
        font-weight: 800;
    }

    .bottom .text2{
        font-size: 25px;
        font-weight: 500;
    }

    .bottom .text3{
        font-size: 14px;
        font-weight: 400;
        width: 51%;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .text1, .text2, .text3{
        display: block;
    }

    .header-li-bottom{
        width: 150px;
        height: 45px;
        border: 1px solid #fff;
        border-radius: 45px;
        margin-right: 10px;
        line-height: 45px;
        text-align: center;
    }

    .header-li-bottom:hover{
        scale: 1.1;
    }

    .header-li-bottom:first-child{
        background-color: #006FFF;
        border-color: #006FFF;
    }

    .header-li-bottom img{
        vertical-align: middle;
    }
</style>