<template>
    <div id ='tech'>
        <HomeHeader></HomeHeader>
        <HomeBanner></HomeBanner>
        <div>
            <div class="techdisplay">
                <div class="hd">
                    <p>
                    </p>
                    <br>
                    <h2 style="text-align:center">自主专利技术</h2>
                </div>
                <div class="ct">
                    <div class="textcontent">
                    <h2 style="text-align:center">超级可扩展分子动力学算法：随机分批Ewald算法</h2>
                    <p></p>
                        <img src = "/image/Ewald.png" class="imgs">
                    </div>
                </div>
            </div>
        </div>
        <HomeFooter></HomeFooter>
    </div>
</template>



<script>
    import HomeHeader from '../Header'
    import HomeBanner from '../Home/Homecomponents/Banner'
    import HomeFooter from '../Footer'
    import Techcontent from './techcontent.vue'
    export default {
        name: "Home",
        components:{
            HomeHeader,
            HomeBanner,
            HomeFooter,
            Techcontent,
        },
        data(){
            return{
                
                
            }
        },

    }
</script>

<style>
    html,body,
    #tech{
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        position: absolute;
    }
    .techdisplay{
        width: 100%;
        height: 900px;
        float: left;
    }
    .hd{
        width:90%;
        height: 15%;
        margin-top:5%;
        margin-left: 5%;
    }
    .ct{
        width: 90%;
        height: 80%;
        background-color: rgb(255, 255, 255);
        margin-left: 5%;
        border:3px solid royalblue;
        float: left;
    }
    .textcontent{
        height: 100%;
        width: 90%;
    }
    .imgs{
        width: 100%;
    }
    .imgcontent{
        width: 30%;
        float: right;
        margin-right: 2%;
    }


</style>