<template>
    <div class="yewu">
        <div class="yewu01">
            <img src="/image/img-8.jpg">
            <p style="position: absolute;color: white;top:130px;left:350px;font-size:35px ">
                计算化学及人工智能驱动的创新药研发方案解决商
            </p>
        </div>
        <div class="yeWu-content">
            <div class="yeWu-content_i">
                <p>
                    计算化学及人工智能驱动的创新药研发方案解决商计算化学及人工智能驱动的创新药研发方案解决商计算化学及人工智能驱动的创新药研发方案解决商
                    计算化学及人工智能驱动的创新药研发方案解决商计算化学及人工智能驱动的创新药研发方案解决商计算化学及人工智能驱动的创新药研发方案解决商
                    计算化学及人工智能驱动的创新药研发方案解决商计算化学及人工智能驱动的创新药研发方案解决商计算化学及人工智能驱动的创新药研发方案解决商
                    计算化学及人工智能驱动的创新药研发方案解决商计算化学及人工智能驱动的创新药研发方案解决商计算化学及人工智能驱动的创新药研发方案解决商
                </p>

                <p>
                    计算化学及人工智能驱动的创新药研发方案解决商计算化学及人工智能驱动的创新药研发方案解决商计算化学及人工智能驱动的创新药研发方案解决商
                    计算化学及人工智能驱动的创新药研发方案解决商计算化学及人工智能驱动的创新药研发方案解决商计算化学及人工智能驱动的创新药研发方案解决商
                    计算化学及人工智能驱动的创新药研发方案解决商计算化学及人工智能驱动的创新药研发方案解决商计算化学及人工智能驱动的创新药研发方案解决商
                    计算化学及人工智能驱动的创新药研发方案解决商计算化学及人工智能驱动的创新药研发方案解决商计算化学及人工智能驱动的创新药研发方案解决商
                </p>
                <img src="/image/img/img_9.png">
            </div>
        </div>
    </div>
</template>

<script>
    import {WOW} from 'wowjs';
    export default {
        name: "",
        data(){
            return {
            };
        },
        mounted(){
            //new WOW().init();  直接引用，默认配置，如果需要修改配置用下面的一段代码
            let wow = new WOW({
                boxClass: 'wow',
                animateClass: 'animated',
                offset: 0,
                mobile: true,
                live: true
            });
            wow.init();
        },
        methods:{
            clickitem(num,nownum)
            {
                nownum = this.istrue;
                console.log(num,nownum);
                if(num == 101)
                {
                    if(nownum<6)
                    {
                        nownum=nownum+1;
                    }
                    else
                    {
                        nownum=0
                    }

                }
                else if(num==100)
                {
                    if(nownum>0)
                    {
                        nownum=nownum-1;
                    }
                    else
                    {
                        nownum=6;
                    }
                }
                else{
                    nownum = num;
                }
                return nownum;
            }
        }
    }
</script>

<style>
    .yewu{
        width: 100%;
        position: relative;
        float: left;
        background-color: #f5f5f5;
        top:110px;
    }
    .yewu01{
        width: 100%;
        height: 400px;
        float: left;
        position: relative;
    }
    .yewu01 img{
        width: 100%;
        height: 400px;
    }
    .yewu .yeWu-content{
        width: 100%;
        float: left;
        position: relative;
    }
    .yeWu-content_i{
        width: 84%;
        margin-left: 8%;
        float: left;
        position: relative;
        margin-top: 40px;
    }
    .yeWu-content_i p{
        line-height: 32px;
        margin-bottom: 20px;
    }
    .yeWu-content_i img{
        width: 100%;
        height: 400px;
    }

</style>