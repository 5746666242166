<template>
  <div>
  <coreHeader></coreHeader>
  <coreBanner></coreBanner>
  <coreContent></coreContent>
  <coreFooter></coreFooter>
  </div>

</template>

<script>
  import coreHeader from '../Header'
  import coreBanner from './corecomponent/core_tec_Banner'
  import coreContent from './corecomponent/core_tec_Denivo'
  import coreFooter from '../Footer'

  export default {
    name: "Core",
    components:{
      coreHeader,
      coreBanner,
      coreContent,
      coreFooter
    }

}
</script>

<style scoped>

</style>