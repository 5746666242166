import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'
import Job from '../views/Job/Job.vue'
import New from '../views/News/New.vue'
import AboutUs from '../views/AboutUs/AboutUs.vue'
import NewResearch from '../views/News/Newscomponents/NewResearch'
import NewCooperation from '../views/News/Newscomponents/NewCooperation'
import NewFinance from '../views/News/Newscomponents/NewFinance'
import Researches from '../views/News/Researches'
import Contact from '../views/Contact/contact'
import tech from '../views/Technique/tech.vue'
import style from '../views/core_tec/style.vue'
import De from '../views/core_tec/Denovo.vue'
import sampling from '../views/core_tec/samling.vue'
import YeWu from '../views/Home/YeWu'
import YeWu2 from '../views/Home/yewu02'
import randomclE from '../views/Technique/RandomclEwald.vue'
import rbeAdmmit from '../views/Technique/RBEAdmmit.vue'
import randomEw from '../views/Technique/randomEwarld.vue'
import extentEw from '../views/Technique/ExtentEwald.vue'
import plpips from '../views/proceedurepips/proceed.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Job',
    name: 'Job',
    component: Job,
  },
  {
    path: '/New',
    name: 'New',
    component: New,
    children:[
      {
        path: '/NewResearch',
        name: 'NewResearch',
        component: NewResearch,
      },
      {
        path: '/NewCooperation',
        name: 'NewCooperation',
        component: NewCooperation,
      },
      {
        path: '/NewFinance',
        name: 'NewFinance',
        component: NewFinance,
      },
    ]
  },
  {
    path: '/Researches/:id',
    name: 'Researches',
    component: Researches,
  },

  {
    path:'/AboutUs',
    name:'AboutUs',
    component: AboutUs
  },
  {
    path:'/Contact',
    name:'Contact',
    component: Contact
  },
  {
    path:'/Technique',
    name:'Technique',
    component:tech
  },
  {
    path:'/style',
    name:'style',
    component:style
  },
  {
    path:'/De',
    name:'De',
    component:De
  },
  {
    path:'/sampling',
    name:'sampling',
    component:sampling
  },
  {
    path:'/YeWu',
    name:'YeWu',
    component: YeWu
  },

  {
    path:'/YeWu2',
    name:'YeWu2',
    component: YeWu2
  },
  {
    path:'/Technique',
    name:'Technique',
    component:randomEw,
  },
  {
    path:'/ExtentEwald',
    name:'ExtentEwald',
    component:extentEw
  },
  {
    path:'/RandomclEwald',
    name:'RandomclEwald',
    component:randomclE
  },
  {
    path:'/RBEAdmmit',
    name:'RBEAdmmit',
    component:rbeAdmmit
  },
  {
    path:'/proceedurepips',
    name:'plpips',
    component:plpips
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
