<template>
    <div class="Hardware">
        <div class="top wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">
            <p>
                <span class="text1">硬件产品</span>
                <span class="text2">标准化硬件模块，机械零件、传动部件，可以灵活组装搭建实现创作者想法</span>
            </p>
        </div>
        <div class="button">
            <img @click="prevSlide" src="/image/home/left-big.png" alt="">
            <img @click="nextSlide" src="/image/home/right-big.png" alt="">
        </div>
        <el-carousel :loop="false" @change="carouselChange" ref="carousel" :autoplay="false" type="card" height="500px" indicator-position="none">
            <el-carousel-item v-for="item in list" :key="item.id" :ref="'ref'+item.id">
                <!-- <h3 class="medium">{{ item }}</h3> -->
                <div class="content" @click="open(item.link)">
                    <div class="left">
                        <img class="board" :src="item.img" alt="" >
                    </div>
                    <div class="right">
                        <p class="title">{{item.title}}</p>
                        <p class="detail">
                            {{ item.content }}
                        </p>
                        <!-- <div class="hardware-detail">
                            <router-link to="/Job" class="router">
                                了解详情 <img src="/image/home/right-lan.png" alt="">
                            </router-link>
                        </div> -->
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
    export default {
        name: "",
        data(){
            return{
                list:[
                    {
                        id: 0,
                        img: '/image/hardware1.png',
                        title: 'STM32入门开发板',
                        link: 'https://rw7mwduiy2.feishu.cn/wiki/E1K4wOSLRiZBbZkuP61cf8SknTh',
                        content: '- 集成代码下载调试器\n- 串口下载代码接口\n- 按键输入*2\n- 显示：LED、数码管、OLED屏\n- 直流电机驱动\n- 模拟量输入：可调电阻、温度传感器、光敏传感器\n- 总线功能：I2C、SPI、USART、RS232、RS485\n- PWM功能、定时器\n- 蜂鸣器\n- 单总线幻彩灯WS2812'
                    },
                    {
                        id: 1,
                        img: '/image/hardware2.png',
                        title: 'STM32综合实验平台',
                        link: '#',
                        content: '- 提供标准10Pin JTAG和SWD调试口\n- 本地、远程调试器可选\n- 宽电压输入9~32V\n- 3.3V、5V、12V输出，最大支持2A电流持续输出\n- USB设备插入方便文件拷贝\n - 所有I/O引出\n- 可配置LED灯指示，监控每一个I/O状态\n- 支持STM32全系列核心板替换\n- MikroBUS标准模块接口（电机、温度、湿度、模拟电压模块)\n- CAN、R232、RS485、USART通用总线\n- TFT、1602显示屏\n- 高精度REF参考电压可选'
                    },
                    {
                        id: 2,
                        img: '/image/hardware3.png',
                        title: '无线网络调试器',
                        link: '#',
                        content: '跨区域无线代码下载调试\n- 移动机器人小车代码下载、数据监控录制\n- 串口数据监控功能调试\n- RJ45以太网连网连接\n- 数据记录远程保存访问\n- 支持多种下载器（Jlink、CMSIS-DAP、STLINK、FPGA调试器）\n- 搭配开发板实现远程开发板共享\n - 远程实验室硬件资源共享\n'
                    },
                ]
            }
        },
        mounted() {
            this.carouselChange(0);
        },
        methods: {
            prevSlide() {
                this.$refs.carousel.prev()
            },
            nextSlide() {
                this.$refs.carousel.next();
            },
            carouselChange(now,old){
                let prev = now - 1
                let next = now + 1
                if(prev >= 0){
                    this.$refs['ref'+prev][0].$el.style.left = '-30%'
                }
                if(next < this.list.length){
                    this.$refs['ref'+next][0].$el.style.left = '28%'
                }

                let prevO = old - 1
                let nextO = old + 1
                if(prevO >= 0){
                    this.$refs['ref'+prevO][0].$el.style.left = '0%'
                }
                if(nextO < this.list.length){
                    this.$refs['ref'+nextO][0].$el.style.left = '0%'
                }
                // this.$refs['ref'+now][0].$el.style.left = '-15%'
                // this.$refs['ref'+next][0].$el.style.left = '28%'
                
            },
            open(link){
                if(link == "#"){
                    return
                }
                window.open(link);
            }
        }
    }
</script>

<style scoped>
    .Hardware{
        /* width: 1500px; */
        background-color: #fff;
        padding-top: 60px;
    }
    .top{
        text-align: center;
    }

    .top p{
        margin: 0;
    }

    .top span{
        display: block;
    }

    .top .text1{
        font-size: 30px;
        font-weight: 600;
        color: #2B333F;
    }

    .top .text2{
        font-size: 16px;
        font-weight: 400;
        color: #68747F;
        margin-top: 15px;
    }

    .button{
        text-align: center;
    }

    .button img{
        width: 68px;
        height: 50px;
        margin: 20px;
        margin-left: 0;
        cursor: pointer;
    }
    /* .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
    } */
    .el-carousel__item .content{
        display: flex;
        width: 100%;
        height: 100%;
    }

    .el-carousel__item .content .left{
        flex: 6;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .el-carousel__item .content .board{
        width: 370px;
        /* height: 280px; */
    }

    .el-carousel__item .content .right{
        flex: 4;
        background-color: #006fff;
        color: #fff;
        padding: 40px;
        padding-top: 20px;
    }

    .el-carousel__item  .right .title{
        font-size: 20px;
        font-weight: 600;
    }

    .el-carousel__item  .right .detail{
        line-height: 25px;
        font-size: 16px;
        font-weight: 400;
        white-space: pre-line;
    }

    .el-carousel__item .hardware-detail{
        width: 150px;
        height: 45px;
        border: 1px solid #006FFF;
        border-radius: 45px;
        margin-right: 10px;
        line-height: 45px;
        text-align: center;
        background-color: #fff;
        margin-top: 50px;
    }

    .el-carousel__item .hardware-detail .router{
        text-decoration: none;
        display:block;
        outline:0;
        color: #006FFF;
    }

    .el-carousel__item .hardware-detail img{
        vertical-align: middle;
    }
    .el-carousel__item {
        background-color: #f3f7fb;
    }
</style>