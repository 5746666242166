<template>
    <div class="Develop-counter">
        <div class="Develop">
            <div class="top">
                <div class="title">仿真平台</div>
                <div class="desc">嵌入式开发者学习和实践的虚拟环境，配套学习资料与视频，用户可以将创意与作品进行共享。</div>
                <!-- <div class="detail">
                    详细介绍 <img src="/image/home/right-hui.png" alt="">
                </div> -->
            </div>
            <div class="Develop_content wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">
                <div class="videomasks" >
                    <video controls='controls' poster="/image/home/vedio.jpg" class="my-video">
                        <source src="/image/0529.mp4" type="video/mp4"/>
                    </video>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    export default {
        name: ""
    }
</script>

<style scoped>
    .Develop-counter{
        background-color: #fff;
        /* width:1500px; */
        height:640px;
        display: flex;
        justify-content: center;
    }
    .my-video{
        width: 100%;
        object-fit: cover;
    }

    .Develop{
        position: relative;
        top: -30px;
        padding-top: 30px;
        width: 930px;
        height:620px;
        background: linear-gradient(180deg, rgba(255,255,255,0.81) 0%, #F2F6FA 4%);
        box-shadow: inset 0px 3px 1px 0px #FFFFFF, 0px 6px 32px 0px rgba(0,82,205,0.15);
        border-radius: 4px 4px 4px 4px;
    }

    .top{
        text-align: center;
    }

    .top .title{
        font-size: 30px;
        font-weight: 600;
        color: #2B333F;
    }

    .top .desc{
        font-size: 16px;
        font-weight: 400;
        color: #2B333F;
        margin: 20px 0;
    }

    .top .detail{
        font-size: 16px;
        font-weight: 500;
        color: #88909B;
        margin-bottom: 10px;
    }
    .top .detail img{
        vertical-align: middle;
    }




</style>