<template>
    <div id="Home">
        <HomeHeader></HomeHeader>
        <HomeDevelop></HomeDevelop>
        <HomeHardware></HomeHardware>
        <!-- <HomeSystem></HomeSystem> -->
        <HomeCooperate></HomeCooperate>
        <HomeAbountUs></HomeAbountUs>
        <HomeLogo></HomeLogo>
        <HomeMap></HomeMap>
        <HomeFooter></HomeFooter>
    </div>
</template>
<script>
    import HomeHeader from '../Header'
    import HomeFooter from '../Footer'
    import HomeHardware from './Homecomponents/Hardware'
    import HomeDevelop from './Homecomponents/Develop'
    import HomeSystem from "./Homecomponents/System"
    import HomeCooperate from './Homecomponents/Cooperate'
    import HomeAbountUs from "./Homecomponents/AboutUs"
    import HomeLogo from "./Homecomponents/Logo"
    import HomeMap from './Homecomponents/Map'
    export default {
        name: "Home",
        components:{
            HomeHeader,
            HomeFooter,
            HomeDevelop,
            HomeHardware,
            HomeSystem,
            HomeCooperate,
            HomeAbountUs,
            HomeLogo,
            HomeMap
        },
        data(){
            return{

            }
        },

    }
</script>

<style>
    html,body,#app,
    #Home{ 
        width: 1500px;
        height: 100%;
        margin: 0;
        padding: 0;
        position: absolute;
        transform-origin: top left;
    }
</style>