<template>
  <img src="/image/Denovo.png" style="width:100%">
</template>

<script>
export default {
  name: "Banner1"
}
</script>

<style scoped>
img{
  width: 100vh;
}
</style>