<template>
    <div class="Cooperate">
        <div class="top">
            <div class="title wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">合作案例</div>
            <div class="company wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.4s">
                <img src="/image/home/cooperate.png" class="cooperate-img" alt="">
                <div class="item">
                    <img src="/image/home/cooperate-logo.png" alt="">
                    <p class="name">湖南智擎科技有限公司</p>
                    <p class="desc">
                        湖南智擎科技有限公司（“智擎科技”） 是一家人工智能软件技术驱动的高新技术企业，致力于用人工智能和软件工程技术赋能高教行业、军工行业、信创行业等不同应用领域。
                    </p>
                    <!-- <div class="company-detail">
                        <router-link to="/Job" class="router">
                            了解详情 <img src="/image/home/right-lan.png" alt="">
                        </router-link>
                    </div> -->
                </div>
                <div class="item">
                    <img src="/image/home/cooperate-logo.png" alt="">
                    <p class="name">头歌实践教学平台</p>
                    <p class="desc">
                        头歌，全称“头歌实践教学平台”，是国内高校广泛使用的在线实践教学服务平台与创新环境，为高校和企业的实践与创新能力提升赋能，是中国高校计算机教育MOOC联盟实践教学工委、全国人工智能职业教育集团实践教学工作委员会官方合作平台。
                    </p>
                    <!-- <div class="company-detail">
                        <router-link to="/Job" class="router">
                            了解详情 <img src="/image/home/right-lan.png" alt="">
                        </router-link>
                    </div> -->
                </div>
                    
            </div>
        </div>
        <div class="bottom wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">
            <div class="item">
                <img src="/image/home/ultrasonic.png" alt="">
                <span class="text1">超声波仿真</span>
                <span class="text2">ULTRASONIC SIMULATION</span>
            </div>
            <div class="item">
                <img src="/image/home/conversion.png" alt="">
                <span class="text1">AD转换原理</span>
                <span class="text2">CONVERSION PRINCIPLE</span>
            </div>
            <div class="item">
                <img src="/image/home/virtual-display.png" alt="">
                <span class="text1">虚拟显示屏</span>
                <span class="text2">VIRTUAL DISPLAY SCREEN</span>
            </div>
            <div class="item">
                <img src="/image/home/ultrasonic.png" alt="">
                <span class="text1">直流电机驱动原理仿真</span>
                <span class="text2">DC MOTOR DRIVE</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: ""
    }
</script>

<style scoped>
    .Cooperate{
        /* width: 1500px; */
        background-image: url("/image/home/cooperate-bg.jpg");
        background-size: cover;
    }

    .top{
        /* background-color: #000e27; */
        padding-top: 30px;
    }

    .top .title{
        color: #fff;
        font-size: 32px;
        font-weight: 600;
        text-align: center;
    }

    .top .company{
        margin-top: 40px;
        display: flex;
        justify-content: center;
        position: relative;
    }   

    .top .item{
        padding: 30px;
        background-color: #fff;
        width: 470px;
        height: 270px;
        background: rgba(255,255,255,0.86);
        box-shadow: inset 0px 3px 1px 0px #FFFFFF;
        border-radius: 8px 8px 0px 0px;
    }
    .top .item:first-child{
        margin-right: 30px;
    }
    .top .item:last-child{
        margin-left: 30px;
    }

    .company .cooperate-img{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 60px;
        height: 60px;
    }

    .company .name{
        font-size: 20px;
        font-weight: 600;
        color: #2B333F;
    }

    .company .desc{
        font-size: 15px;
        font-weight: 400;
        color: #68747F;
    }

    .company .company-detail{
        width: 150px;
        height: 45px;
        border: 1px solid #006FFF;
        border-radius: 45px;
        margin-right: 10px;
        line-height: 45px;
        text-align: center;
        margin-top: 30px;
    }

    .company .company-detail .router{
        text-decoration: none;
        display:block;
        outline:0;
        color: #006FFF;
    }

    .company .company-detail img{
        vertical-align: middle;
    }

    .bottom{
        height: 220px;
        background: rgba(0,111,255,0.72);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bottom .item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 230px;
        height: 150px;
        /* background-color: #ccc; */
        color: #fff;
        border-right: 1px solid rgba(255,255,255,0.4);
    }

    .bottom .item:last-child{
        border-right: none;
    }

    .bottom .item .text1{
        font-size: 18px;
        font-weight: 600;
    }

    .bottom .item .text2{
        font-size: 12px;
        margin-top: 5px;
        font-weight: 550;
    }
</style>