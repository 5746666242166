<template>
    <div id ='tech'>
        <HomeHeader></HomeHeader>
        <HomeBanner></HomeBanner>
        <Techcontent></Techcontent>
        <HomeFooter></HomeFooter>
    </div>
</template>



<script>
    import HomeHeader from '../Header'
    import HomeBanner from '../Home/Homecomponents/Banner'
    import HomeFooter from '../Footer'
    import Techcontent from './techcontent.vue'
    export default {
        name: "Home",
        components:{
            HomeHeader,
            HomeBanner,
            HomeFooter,
            Techcontent,
        },
        data(){
            return{
                
                
            }
        },

    }
</script>

<style>
    html,body,
    #tech{
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        position: absolute;
    }


</style>