<template>
    <div class="AboutUs wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.4s">
        <p>
                - LOGO -
        </p>
        <div class="content">
            <img src="/image/home/red.png" alt="">
            <img src="/image/home/yellow.png" alt="">
            <img src="/image/home/blue.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: ""
    }
</script>

<style scoped>
    .AboutUs{
        /* width: 1500px; */
        background-color: #fff;
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .AboutUs p{
        font-size: 24px;
        font-weight: 600;
        color: #2B333F;
    }

    .content img{
        width: 320px;
        margin-right: 25px;
    }

    .content img:last-child{
        margin-right: 0;
    }
</style>