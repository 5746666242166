<template>
  <div>
    <center><h1>增强采样助力构效关系</h1></center>
    <center><div id="box">强采样助力构效关系强采样助力构效关系强采样助力构效关系强采样助力构效关系增强采样助力构效关系增强采样助力构效关系增强采样助力构效关系增强采样助力构效关系增强采样助力构效关系增强采样助力构效关系增强采样助力构效关系增强采样助力构效关系</div></center>
    <ul>
      <li>
        <p>标题1</p><br>
        <span>xxxxxxxxxxxxxxxxxxxx<br>
        xxxxxxxxxxxxxxxxxxxx<br>
        xxxxxxxxxxxxxxxxxxxx<br>
        xxxxxxxxxxxxxxxxxxxx<br></span>
      </li>
      <li>

        <p>标题2</p><br>
        <span>xxxxxxxxxxxxxxxxxxxx<br>
        xxxxxxxxxxxxxxxxxxxx<br>
        xxxxxxxxxxxxxxxxxxxx<br>
        xxxxxxxxxxxxxxxxxxxx<br></span>
      </li>
      <li>
        <p>标题3</p><br>
        <span>xxxxxxxxxxxxxxxxxxxx<br>
        xxxxxxxxxxxxxxxxxxxx<br>
        xxxxxxxxxxxxxxxxxxxx<br>
        xxxxxxxxxxxxxxxxxxxx<br></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "introduce1"
}
</script>

<style scoped>
*{
  list-style: none;

}
#box{
  position:relative;
  width:800px;
  height:150px;

}
ul{
  overflow: hidden;
  height: 300px;
}
ul li{
  position:relative;
  background: #fff;
  box-sizing: border-box;
  border: 1px solid #333;
  height: 300px;
  width: 15%;
  float:left;
  margin-left:10%;
}

ul li:last-child{
  margin-right: 100px;
}
ul li p{
  text-align: center;
}
ul li span{
  display: block;
  text-align: center;
  overflow:hidden;
}
</style>