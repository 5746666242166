<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
         name: 'App',
        mounted() {
            let width = (window.screen.width - 25) / 1500
            console.log(document.getElementById("app"))
            document.getElementById("app").style.transform = 'scale(' + width + ')'
        },
    }
</script>

<style>
</style>