<template>
    <div class="techdisplay">
        <div class="hd">
            <p>
            </p>
            <br>
            <h2 style="text-align:center">自主专利技术</h2>
        </div>
        <div class="ct">
            <div class="textcontent">
            <h2 style="text-align:center">{{item.name}}</h2>
            <p></p>{{item.content}}
            </div>
            <div class="imgcontent">
                <img :src="imgurl" class="imgs">
            </div>
        </div>
    </div>
</template>
<script>
    import pic1 from '../../../public/image/Ewald.png'
    import pic2 from '../../../public/image/Ewald.png'
    export default {
        data(){
            return{
                id : this.$route.params.entityId,
                item:[],
                techlist:[
                    {
                        id:0,name:"超级可扩展分子动力学算法：随机分批Ewald算法",
                        content:""
                        ,img:pic1
                    },
                    {
                        id:1,name:"超级可扩展分子动力学算法：RBE算法广受认可",
                        content:"通过随机选取部分粒子对的相互作用来代替整体相互作用： O(N2) —〉O(N); 证明了收敛性"
                        ,img:pic1
                    },
                    {
                        id:2,name:"RBE提高并行效率及计算速度",
                        content:"分子动力学随机小批分子静电相互作用算法分子动力学随机小批分子静电相互作用算法分子动力学随机小批分子静电相互作用算法分子动力学随机小批分子静电相互作用算法分子动力学随机小批分子静电相互作用算法"
                        ,img:pic2
                    },
                    {
                        id:3,name:"RBE精确重现计算精度",
                        content:"分子动力学随机小批分子静电相互作用算法分子动力学随机小批分子静电相互作用算法分子动力学随机小批分子静电相互作用算法分子动力学随机小批分子静电相互作用算法分子动力学随机小批分子静电相互作用算法"
                        ,img:pic2
                    },
                ]
            }
        },
        mounted()
        {
            for (var i = 0;i<this.techlist.length;i++)
            {
                if(this.techlist[i].id == this.id)
                {
                    this.item = this.techlist[i]
                    this.imgurl = this.techlist[i].img
                }
            }
            
        }

    }
</script>

<style>
    .techdisplay{
        width: 100%;
        height: 900px;
        float: left;
    }
    .hd{
        width:90%;
        height: 15%;
        margin-top:5%;
        margin-left: 5%;
    }
    .ct{
        width: 90%;
        height: 80%;
        background-color: rgb(255, 255, 255);
        margin-left: 5%;
        border:3px solid royalblue;
        float: left;
    }
    .textcontent{
        width: 70%;
    }
    .imgs{
        width: 100%;
        
    }
    .imgcontent{
        width: 30%;
        float: right;
        margin-right: 2%;
    }
</style>