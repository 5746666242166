<template>
    <div class="Footer">
        <div class="infor">
            <div class="left">
                <img class="logo" src="/image/home/logo2.png" alt="">
                <div class="phone">
                    <img src="/image/home/phone.png" alt="">
                    <span>18390824675</span>
                </div>
            </div>
            <div class="right">
                <div>
                    <img src="/image/home/email.png" alt="">
                    <span>wangls121@163.com</span>
                </div>
                <div>
                    <img src="/image/home/locate.png" alt="">
                    <span>长沙经济技术开发区三一路1号三一工业城众创楼三一众创孵化器3056</span>
                </div>
                <div>
                   <span>Copyright ©长沙云烽智联科技有限公司 版权所有 &nbsp; </span>
                   <a href="https://beian.miit.gov.cn/" target="_blank" style=" color:#939393; display:inline-block;text-decoration:none;height:20px;line-height:20px;">湘ICP备2023021496号 &nbsp;</a>
                   <img style="margin-right: -2px;height: 17px;" src="/image/home/ba.png" alt=""><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43012102000937" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">湘公网安备 43012102000937号</p></a>
                </div>
            </div>
        </div>


        <div class="code">
            <div class="item">
                <img src="/image/gongzh.png" alt="">
                <span>关注公众号</span>
            </div>
            <!-- <div class="item">
                <img src="/image/img_6.jpg" alt="">
                <span>添加企业微信</span>
            </div> -->
        </div>
    </div>
</template>

<script>
    export default {
        name: ""
    }
</script>

<style scoped>
    .Footer{
        /* width: 1500px; */
        height: 250px;
        background-color: #0B1D2F;
        display: flex;
        align-items: center;
    }

    .infor{
        flex: 7;
        display: flex;
        align-items: center;
        padding-left: 50px;
    }

    .infor .left{
        margin-right: 40px;
    }

    .infor .left .logo{
        width: 190px;
        height: 48px;
        margin-bottom: 20px;
    }

    .infor .phone{
        width: 190px;
        height: 40px;
        background-color: #006fff;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 4px;
    }

    .infor .phone img{
        margin-right: 5px;
        width: 28px;
        height: 28px;
    }

    .infor .right div{
        display: flex;
        align-items: center;
        color: #A8B0BB;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .infor .right img{
        margin-right: 10px;
    }   
    .code{
        flex: 3;
        display: flex;
        justify-content: flex-end;
        padding-right: 50px;
    }

    .code img{
        width: 108px;
        height: 108px;
        margin-bottom: 10px;
    }
    
    .code .item{
        display: flex;
        flex-direction: column;
        text-align: center;
        color: #fff;
        margin-right: 30px;
    }
   
</style>