<template>
    <div class="System">
        <div class="top wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">
            <p>
                <span class="text1">课程体系</span>
                <span class="text2">围绕“仿真平台+硬件+课程体系”三个板块，帮助硬件初学者更简单快乐的学习</span>
            </p>
        </div>
        <div class="content wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"  active-text-color="#006fff">
                <el-menu-item index="1">硬件课程</el-menu-item>
                <el-menu-item index="2">硬件课程</el-menu-item>
                <el-menu-item index="3">硬件课程</el-menu-item>
                <el-menu-item index="4">硬件课程</el-menu-item>
                <el-menu-item index="5">硬件课程</el-menu-item>
                <el-menu-item index="6">硬件课程</el-menu-item>
            </el-menu>
            <div class="course">
                <div class="left">
                    <div class="title">硬件课程</div>
                    <div class="desc">硬件课程硬件课程硬件课程</div>
                    <ul class="course-ul">
                        <li><img src="/image/home/li.png" alt=""> 围绕“仿真平台+硬件+课程体系”三个板块，帮助硬件初学者更简单快乐的学习</li>
                        <li><img src="/image/home/li.png" alt=""> 围绕“仿真平台+硬件+课程体系”三个板块，帮助硬件初学者更简单快乐的学习</li>
                        <li><img src="/image/home/li.png" alt=""> 围绕“仿真平台+硬件+课程体系”三个板块，帮助硬件初学者更简单快乐的学习</li>
                        <li><img src="/image/home/li.png" alt=""> 围绕“仿真平台+硬件+课程体系”三个板块，帮助硬件初学者更简单快乐的学习</li>
                    </ul>
                    <div class="course-detail">
                        <router-link to="/Job" class="router">
                            了解详情 <img src="/image/home/right.png" alt="">
                        </router-link>
                    </div>
                </div>
                <div class="right">
                    <img src="/image/home/cource.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "",
        data() {
            return {
                activeIndex: '1',
            };
        },
        methods: {
            handleSelect(key, keyPath) {
                console.log(key, keyPath);
            }
        }
    }
</script>

<style scoped>
    .System{
        /* width: 1500px; */
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
    }
    .top{
        text-align: center;
    }

    .top span{
        display: block;
    }

    .top .text1{
        font-size: 30px;
        font-weight: 600;
        color: #2B333F;
    }

    .top .text2{
        font-size: 16px;
        font-weight: 400;
        color: #68747F;
        margin-top: 10px;
    }

    .content{
        background-color: #fff;
        width: 930px;
        padding: 10px;
    }

    ul.el-menu-demo.el-menu--horizontal.el-menu {
        padding: 0 20px;
    }
    .el-menu-item{
        font-size: 18px;
    }

    .course{
        color: #2B333F;
        display: flex;
    }

    .course .course-ul{
        list-style-type: none;
        padding: 0;
    }

    .course .left{
        flex: 7;
    }

    .course .right{
        flex: 3;
    }

    .course .title{
        margin-top: 30px;
        font-size: 20px;
    }

    .course .desc{
        font-size: 16px;
        margin: 15px 0;
    }

    .course li{
        margin-bottom: 10px;
    }

    .course .right img{
        margin-top: 50px;
        width: 330px;
        height: 280px;
    }

    .course .course-detail{
        width: 150px;
        height: 45px;
        border: 1px solid #006FFF;
        border-radius: 45px;
        margin-right: 10px;
        line-height: 45px;
        text-align: center;
        background-color: #006FFF;
        margin-top: 30px;
    }

    .course .course-detail .router{
        text-decoration: none;
        display:block;
        outline:0;
        color: #fff;
    }

    .course .course-detail img{
        vertical-align: middle;
    }
</style>