<template>
    <div id ='tech'>
        <HomeHeader></HomeHeader>
        <HomeBanner></HomeBanner>
        <div>
            <div class="techdisplayA">
                <div class="hdA">
                    <p>
                    </p>
                    <br>
                    <h2 style="text-align:center">自主专利技术</h2>
                </div>
                <div class="ctA">
                    <div class="textcontentA">
                        <h2 style="text-align:center">超级可扩展分子动力学算法：RBE算法广受认可</h2>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过随机选取部分粒子对的相互作用来代替整体相互作用： O(N2) —〉O(N); 证明了收敛性</p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;参与专家:</p>
                        <div class="professor">
                            <div class="picimg">
                                <img src = "/image/Professor/JinShi.jpg">
                                <p>金石</p>
                                <p class="pt">自然院/数学学院</p>
                            </div>
                            <div class="picimg">
                                <img src = "/image/Professor/LiLei.jpg">
                                <p>李磊</p>
                                <p class="pt">自然院/数学学院</p>
                            </div>
                            <div class="picimg">
                                <img src = "/image/Professor/XuZhenLi.jpg">
                                <p>&nbsp;&nbsp;徐振礼</p>
                                <p class="pt">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数学学院</p>
                            </div>
                        </div>
                        <p>&nbsp;&nbsp;</p>
                        <p>&nbsp;&nbsp;</p>
                        <p>&nbsp;&nbsp;</p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;·J Comput Phys 400 (2020)； Commun. Comm. Comp. Phys. 28, 1907–1936, (2020).</p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;·SIAM J Sci Comput, 42, A1486-A1509 (2020).  SIAM J. Sci. Comput. 43 (2021), 937 (2021) </p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;顶级期刊荣誉:</p>
                        <img src="/image/TopJournal.png">
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“RBM算法或可产生与20世纪十大算法相似的重要影响”——Robert Pego Former Chief Editor
                            SIAM (美国工业与应用数学学会）Journal on Mathematical Analysis </p>
                        <img class="letter" src="/image/LetterOfRecom.png">
                    </div>
                </div>
            </div>
        </div>
        <HomeFooter></HomeFooter>
    </div>
</template>



<script>
    import HomeHeader from '../Header'
    import HomeBanner from '../Home/Homecomponents/Banner'
    import HomeFooter from '../Footer'
    import Techcontent from './techcontent.vue'
    export default {
        name: "Home",
        components:{
            HomeHeader,
            HomeBanner,
            HomeFooter,
            Techcontent,
        },
        data(){
            return{
                
                
            }
        },

    }
</script>

<style>
    html,body,
    #tech{
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        position: absolute;
    }
    .techdisplayA{
        width: 100%;
        height: 2100px;
        float: left;
    }
    .hdA{
        width:90%;
        height: 5%;
        margin-top:5%;
        margin-left: 5%;
    }
    .ctA{
        width: 90%;
        height: 90%;
        background-color: rgb(255, 255, 255);
        margin-left: 5%;
        border:3px solid royalblue;
        float: left;
    }
    .ctA img{
        margin-left: 15%;
    }
    .textcontentA{
        height: 100%;
        width: 90%;
    }
    .textcontentA .letter
    {
        width: 90%;
        margin-left: 10%;
        height: 1150px;
    }
    .imgs{
        width: 100%;
    }
    .imgcontent{
        width: 30%;
        float: right;
        margin-right: 2%;
    }
    .professor
    {
        height: 100px;
        width: 100%;
        float: left;
    }
    .picimg{
        width: 30%;
        float: left;
    }
    .picimg img{
        margin-left: 50%;
    }
    .picimg p{
        margin-left: 59.5%;
    }
    .picimg .pt{
        margin-left: 47%;
    }


</style>