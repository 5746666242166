<template>
    <div class="abIntroduces">
        <div class="ab01">
            <img src="/image/img-8.jpg">
        </div>
        <div class="ab02">
            <div class="abIntro">
                <h1 style="padding-bottom: 20px">云峰智联</h1>
                <p>
                    简介1
                </p>
                <p>
                    简介2
                </p>
            </div>
            <div class="abImg">
                <img src="/image/img/img_2.png">
            </div>
        </div>
        <div class="ab03">
            <div class="ab03-01">
                <div class="abImg03">
                    <img src="/image/img/img_12.png">
                </div>
                <div class="ab03Intro">
                    <h1></h1>
                    <h2 style="color: #666666;text-align: center;font-size: 21px;">
                        
                    </h2>
                </div>


            </div>
        </div>
        <div class="ab04">
            <h1 class="ab-title">
                核心团队
            </h1>
            <div class="ab04-01">
               

            </div>

        </div>

        <div class="ab05">
            <h1 class="About-title">
                宣传视频
            </h1>
            <div class="videomasks" >
                <video controls='controls'>
                    <source src="/image/3.mp4" type="video/mp4"/>
                </video>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "",
    }
</script>
<style>
    .abIntroduces{
        width:100%;
        float: left;
        position: relative;
        overflow:hidden;
        background-color: white;
        top:110px
    }
    .ab01{
        width: 100%;
        height: 400px;
        float: left;
        position: relative;
    }
    .ab01 img{
        width: 100%;
        height: 400px;
    }
    .ab02{
        width: 84%;
        height: 600px;
        float: left;
        position: relative;
        margin-left: 8%;
        margin-right: 8%;
    }
    .abIntro{
        width: 56%;
        height: 550px;
        float: left;
        padding-top: 50px;
    }
    .abIntro p{
        margin-bottom: 35px;
        color:#3f3f3f;
        font-size: 15px;
        font-weight: bold;
        line-height: 30px;
    }
    .abImg{
        width: 40%;
        height: 100%;
        float: left;
        margin-left: 4%;
    }
    .abImg img{
        width: 100%;
        height: 350px;
        margin-top: 165px;
    }

    .ab03{
        width: 100%;
        height: 680px;
        float: left;
        position: relative;
        background-color: #f5f5f5;
    }
    .ab03-01{
        width: 84%;
        background-color: white;
        height: 550px;
        margin-top:65px;
        margin-left: 8%;
        margin-right: 8%;
    }
    .abImg03{
        width: 30%;
        height: 550px;
        float: left;
        position: relative;
    }
    .abImg03 img{
        width: 202px;
        height: 243px;
        text-align: center;
        margin-left: 180px;
        margin-top: 152px;
    }
    .ab03Intro{
        width: 65%;
        height: 600px;
        float: left;
        position: relative;
        margin-left: 3%;
    }
    .ab03Intro h1{
        text-align: center;
    }
    .ab03Intro p{
        margin-left: 110px;
        font-weight: bold;
        color:#3f3f3f;
        font-size: 15px;
    }
    .ab03Intro span{
        font-size: 19px;
        margin-left: 100px;
        font-weight: bold;
        color:#3f3f3f;
    }
    .ab03Intro ul{
        list-style-type: none;
        padding-inline-start: 0;
        margin-left: 110px;
    }
    .ab03Intro ul li{
        float: left;
        position: relative;
        font-weight: bold;
        color: #3f3f3f;
        font-size: 15px;
        margin-block-end: 1em;
        width: 50%;
    }
    .ab04{
        width: 100%;
        position: relative;
        float: left;
    }
    .ab-title{
        text-align: center;
        margin-top: 60px;
        margin-bottom: 40px;
    }
    .ab04-01{
        width: 84%;
        margin-left: 8%;
        margin-right: 8%;
    }
    .ab04-01 .ab04-01-ul{
        list-style: none;
        padding-inline-start: 0;
    }
    .ab04-01 .ab04-01-li{
        float: left;
        position: relative;
        width: 100%;
        background-color: #f5f5f5;
        margin-bottom: 50px;
        padding-bottom: 30px;
        border-radius: 10px;
    }
    .ab04-title{
        text-align: center;
        color: white;
        background-color: #42b983;
        line-height: 50px;
        height: 50px;
        font-size: 23px;
        margin: 30px  500px;
        border-radius: 10px;
    }
    .ab04-02{
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }
    .ab04-02 .ab04-02-ul{
        padding-inline-start: 25px;
        padding-top: 10px;
    }
    .ab04-03{
        color: #1a1a1a;
        font-size: 23px;
        border-left: 1px solid #42b983;
        margin-bottom: 20px;
        margin-top: 35px;
    }
    .ab04-02 .ab04-02-ul li{
        width: 100%;
        color: #333;
        font-size: 16px;
        line-height: 35px;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .ab05 {
        width: 100%;
        position: relative;
        float: left;
        height: 600px;
        background-color: #fafafa;
    }
    .videomasks{
        width: 80%;
        height: 450px;
        margin-left: 10%;
    }
    .About-title{
        width: 100%;
        text-align: center;
        color: #333333;
        margin-block-start: 1em;
        margin-block-end: 1em;
    }
</style>