<template>
  <div id="Job">
    <job-header @changeOpen="changeOpen"></job-header>
    <div class="content">
      <div class="left" :class="open? '' : 'close-left'"></div>
      <div class="right" :class="open? '' : 'close-right'">
        <el-collapse>
          <el-collapse-item title="分位元器件" name="1">
            <div class="components">
                <div class="item">
                    <img src="/image/job/元件01.png" alt="">
                    <span>可调电阻</span>
                </div>
                <div class="item">
                    <img src="/image/job/元件02.png" alt="">
                    <span>1602液晶</span>
                </div>
                <div class="item">
                    <img src="/image/job/元件03.png" alt="">
                    <span>按键</span>
                </div>
                <div class="item">
                    <img src="/image/job/元件04.png" alt="">
                    <span>电阻</span>
                </div>
                
            </div>
          </el-collapse-item>
          <el-collapse-item title="数字芯片（Digital IC）" name="2">
            <div>
              控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；
            </div>
            <div>页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。</div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import JobHeader from "./Jobcomponents/Header.vue";
export default {
  name: "Job",
  data() {
    return{
        open: true
    }
  },
  components: {
    JobHeader,
  },
  methods: {
    changeOpen() {
        this.open = !this.open
    }
  }
};
</script>

<style scoped>
@media screen and (max-width: 1100px) {
    #Job{
        width: 1500px;
    }
}
.content {
  display: flex;
  width: 100%;
  height: calc(100vh - 110px);
}

.content .left {
  width: 73.3%;
  background-color: antiquewhite;
  border-right: 1px solid #dadfe7;
}

.content .close-left{
    width: 100%;
}

.content .right {
  width: 26.6%;
  box-sizing: border-box;
  padding: 30px 20px;
  height: 100%;
  overflow-y: auto;
}

.content .close-right{
    width: 0;
    padding: 0;
    overflow: hidden;
}

.content .right .el-collapse{
    border-radius: 15px;
    border: 1px solid #dadfe7;
    overflow: hidden;
    /* background-color: #fff; */
}

.content .right .el-collapse-item{
    padding: 0 15px;
    background-color: transparent;
}

::v-deep .content .right .el-collapse-item__header{
    background-color: transparent !important;
    font-weight: bold;
    color: #4D5E80;
    font-size: 16px;
}

::v-deep .content .right .el-collapse-item__wrap{
    background-color: transparent !important;
}

.content .right .components{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.content .right .components .item{
    width: 150px;
    height: 180px;
    background: #FFFFFF;
    box-shadow: 0px 2px 5px 0px rgba(38,51,77,0.03);
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #CED3E7;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #4D5E80;
    font-size: 14px;
}

.components .item img{
    height: 55px;
    margin-bottom: 10px;
}
</style>