<template>
    <div class="AboutUs">
        <div class="top wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">
            <p>
                <span class="text1">关于我们</span>
                <span class="text2">长沙云烽智联科技有限公司是专注于硬件学习与教育的科技公司，其中产品“ECloudSpace”致力于成为新一代硬件学习的领航者。公司由一群来自飞腾、希迪智驾、联想等不同领域的优秀专家汇聚而成，秉承以人为本，坚持创新发展的经营理念，共创数字孪生的新世界。</span>
            </p>
        </div>
        <div class="content wow fadeInUp"  data-wow-duration="1s" data-wow-delay="0.4s">
            <div class="item">
                <img class="img1" src="/image/home/vision.png" alt="">
                <span class="text1">愿景</span>
                <img class="img2" src="/image/home/vision-logo.png" alt="">
                <span class="text2">构建知识学习数字孪生体</span>
            </div>
            <div class="item">
                <img class="img1" src="/image/home/mission.png" alt="">
                <span class="text1">使命</span>
                <img class="img2" src="/image/home/mission-logo.png" alt="">
                <span class="text2">让硬件学习更简单快乐</span>
            </div>
            <div class="item">
                <img class="img1" src="/image/home/values.png" alt="">
                <span class="text1">价值观</span>
                <img class="img2" src="/image/home/values-logo.png" alt="">
                <span class="text2">诚实互助，激情奋进</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: ""
    }
</script>

<style scoped>
    .AboutUs{
        /* width: 1500px; */
        background-color: #fff;
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .top{
        text-align: center;
    }

    .top p{
        margin: 0;
    }

    .top span{
        display: block;
    }

    .top .text1{
        font-size: 26px;
        font-weight: 600;
        color: #2B333F;
    }

    .top .text2{
        font-size: 14px;
        font-weight: 400;
        color: #68747F;
        margin: 20px 0;
        width: 880px;
    }

    .content{
        display: flex;
        justify-content: center;
    }

    .content .item{
        padding-top: 20px;
        width: 320px;
        height: 360px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-right: 25px;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #DADFE7;
    }

    .content .item:last-child{
        margin-right: 0;
    }

    .content .item .img1{
        width: 180px;
        height: 180px;
        border: 1px dashed #ccc;
        margin-top: 5px;
    }

    .content .item .img2{
        width: 70px;
        height: 20px;
        border: 1px dashed #ccc;
        margin-bottom: 5px;
    }

    .content .item .text1{
        font-size: 24px;
        font-weight: 600;
        color: #006FFF;
    }

    .content .item .text2{
        width: 100%;
        height: 60px;
        background-color: #006FFF;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 60px;
        text-align: center;
    }
</style>